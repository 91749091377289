import React from 'react';
import { Navigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { getAccessToken } from '../redux/auth/authSlice';

const ProtectedRoute = ({children, referrer}) => {
    const accessToken = useSelector(getAccessToken)
    return (
      <>
        {
          accessToken
              ? children
              : <Navigate to={!!referrer ? `/?referrer=${referrer}`: '/'} />
        }
      </>
    );
  };
  
  export default ProtectedRoute;