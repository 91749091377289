import React from 'react'

const version = '1.0.6'
const Release = () => {
    return (
        <div>
            status: 200, environment: staging, version: {version}
        </div>
    )
}

export default Release