import React from "react";
import ButtonDefault from "../../../../components/ButtonDefault/ButtonDefault.js";
import "./styles.scss";
import { Helmet } from 'react-helmet'
import { Form, Input, Typography, Row, Col, Image, Modal } from 'antd';
import { useState } from "react";
import Spinner from "../../../../shared/components/spinner/index.jsx";
import { BaseAPI } from "../../../../shared/services/BaseAPI.js";
import Layout from "../../../../shared/components/layout/index.jsx";
import DatePicker from "../../../../shared/components/datePicker/index.jsx";
import moment from "moment";
import useWindowSize from "../../../../shared/hooks/use-window-size.js";
import { FacebookPixel } from "../../../../shared/services/facebook-pixel";

const { Title, Paragraph } = Typography

const Predictor = () => {
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false)
    const [genderAnnounce, setGenderAnnounce] = useState(false)
    const [gender, setGender] = useState(null)
    const {width} = useWindowSize()
    
    const currentYear = new Date().getFullYear();

    const monthConcievedYearRange = {
        [currentYear]: [1, 12],
        [currentYear - 1]: [1, 12],
    };

    const dueDateYearRange = {
        [currentYear]: [1, 12],
        [currentYear + 1]: [1, 12],
    }

    
    const predictGender = (conceived_month, due_date, dob) => {
        const parseDate = (date) => parseInt(date?.substring(0,4)) + parseInt(date?.substring(5,7)) + parseInt(date?.substring(8,))
        const total = parseDate(moment(conceived_month).format('YYYY-MM-00')) + parseDate(due_date) + parseDate(dob)
        return total % 2 === 1 ? 'Girl' : 'Boy'
    }

    const onFinish = async (values) => {
        const payload = {
            conceived_month: moment(values.conceived_month).format('YYYY-MM-01'),
            due_date: moment(values.due_date).format('YYYY-MM-DD'),
            dob: moment(values.dob).format('YYYY-MM-DD'),
            email: values.email,
        }

        setIsLoading(true)

        try {
            await BaseAPI.post(`user/predictor`, payload)

            form.resetFields();
            setGenderAnnounce(true)
            setGender(predictGender(payload.conceived_month, payload.due_date, payload.dob))
            setIsLoading(false)
        } catch (error) {
            
            form.resetFields();
            setGenderAnnounce(true)
            setGender(predictGender(payload.conceived_month, payload.due_date, payload.dob))
            setIsLoading(false)
        }

        if(!!values?.email){
            FacebookPixel.track("Subscribe", {email: values?.email})
        }
    }

    return (
        <>
            <Layout>
                <Helmet>
                    <title>{`Predictor`}</title>
                    <script src="https://unpkg.com/@lottiefiles/lottie-player@0.4.0/dist/tgs-player.js" />
                    <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;0,600;0,700;0,800;0,900;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet" />
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
                </Helmet>
                <div className="predictorContainer">
                    <div className="block1">
                        {
                            width > 450 && 
                            <>
                                <p>This Chinese Gender Predictor Tool is brought to you by:</p>
                                <div>
                                    <b className="bold">Villie Inc.</b>
                                </div>
                            </>
                        }
                        <Title className="title">Curious if you're pregnant with a boy or a girl? <span className="color">Let's see what it will be!</span> </Title>
                        <Image
                            src={"../assets/predictor.jpg"}
                            alt="couple"
                            preview={false}
                            className="image"
                        />
                    </div>

                    <Row className="box" justify="center">
                        <Col xs={0} sm={0} md={12} lg={12} xl={12} className="left">
                            <Row gutter={[0, 35]}>
                                <Col span={24}>
                                    <Title className="title">Curious if you're pregnant with a boy or a girl? <span className="color">Let's see what it will be!</span> </Title>
                                </Col>
                                <Col span={24}>
                                    <Image
                                        src={"../assets/predictor.jpg"}
                                        alt="couple"
                                        preview={false}
                                        className="image"
                                    />
                                </Col>
                                <Col span={24}>
                                    <Paragraph className="paragraph">
                                        In today's digital and tech-savvy world, there are so many fun ways to predict your baby's gender. Yes, one of the most popular old wives' tale ways is using the Chinese gender calendar, which dates back thousands of years. This method tries to predict your baby's gender based on an ancient Chinese gender chart and uses your lunar age and the lunar month you got pregnant to determine the baby's gender.
                                    </Paragraph>
                                    <ul className="paragraph">
                                        <li>Enter your date of birth</li>
                                        <li>Enter your month of conception </li>
                                        <li>Enter your estimated due date</li>
                                        <li>Enter your email address</li>
                                        <li>Click "Predict Baby's Gender"</li>
                                    </ul>
                                    <Paragraph className="paragraph">
                                        Luckily, we've created a tool that combines the ancient lunar calendar with twenty-first-century technology! Our 2023 Chinese Baby Gender Predictor is easy to use. With your information, our calculator will instantly convert your dates into your lunar dates with a final gender prediction. Let's see what it will be!
                                    </Paragraph>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={12} xl={12} className="right">
                            <Form
                                name="register"
                                layout="vertical"
                                style={{ maxWidth: '353px' }}
                                onFinish={onFinish}
                                form={form}
                            >
                                <p className="details">
                                    For centuries, eager parents have looked at the Chinese gender calendar to determine whether they're having a baby boy or girl. 
                                </p>

                                <DatePicker
                                    label="Mom’s Date of Birth"
                                    name="dob"
                                    required
                                    errorMessage={"* What's your date of birth"}
                                    disabledDate={(current) => {
                                        // Get the current date
                                        const currentDate = new Date();
                                        // Disable future dates
                                        return current > currentDate;
                                    }}
                                />

                                <DatePicker
                                    label="Month You Conceived"
                                    name="conceived_month"
                                    required
                                    errorMessage={"* Required"}
                                    picker="month"
                                    format="MM-YYYY"
                                    disabledDate={(current) => {
                                        if (!current) {
                                            return false;
                                        }
                                        const year = current.year();
                                        if (monthConcievedYearRange[year]) {
                                            return false
                                        }
                                        return true;
                                    }}
                                />

                                <DatePicker
                                    label="Estimated Due Date"
                                    name="due_date"
                                    required
                                    errorMessage={"* Required"}
                                    disabledDate={(current) => {
                                        if (!current) {
                                            return false;
                                        }
                                        const year = current.year();
                                        if (dueDateYearRange[year]) {
                                            return false
                                        }
                                        return true;
                                    }}
                                />

                                <Form.Item
                                    label={"Email address"}
                                    name={"email"}
                                    className="formItem"
                                >
                                    <Input className='input' placeholder={"Enter email address"} />
                                </Form.Item>

                                <Paragraph style={{fontSize: "12px", textAlign: "justify"}}>Submit Email Address and Immediately Receive Discount Codes Weekly to Our Favorite Parenting Brands</Paragraph>

                                <Form.Item>
                                    <ButtonDefault
                                        type="submit"
                                        primary
                                        className="predictBtn"
                                    >
                                        {isLoading ? <Spinner forBtn={true} /> : "PREDICT BABY’S GENDER"}
                                    </ButtonDefault>
                                </Form.Item>

                                <div style={{ textAlign: "center" }}>
                                    <p className="details">
                                        You agree to our <a href="https://villie.co/grg-terms" target='_blank' rel="noopener noreferrer">Terms of Use</a> and <a href="https://villie.co/grg-privacy" target='_blank' rel="noopener noreferrer">Privacy Policy</a> by submitting your information. You’re also opting in to receive occasional emails from Villie, the supportive village every parent needs.
                                    </p>

                                    <p className="details">
                                        <i className="exclude">The Chinese gender chart isn’t scientifically based. At the very least, it’s very entertaining!</i>
                                    </p>
                                </div>

                                <div className="block2">
                                    <h3>
                                        Connect with us&nbsp;&nbsp;&nbsp;
                                    </h3>

                                    <div>
                                        <a
                                            target="_blank"
                                            href="https://www.instagram.com/genderrevealgame/"
                                            rel="noreferrer"
                                        >
                                            <Image 
                                            preview={false} 
                                            className="icons" 
                                            alt="instagram" src={"../assets/instagram.svg"}/>
                                        </a>
                                            &nbsp;
                                            &nbsp;
                                        <a
                                            target="_blank"
                                            href="https://www.pinterest.com/genderrevealapp"
                                            rel="noreferrer"
                                        >
                                            <Image 
                                                preview={false} 
                                                className="icons" 
                                                alt="pinterest" 
                                                src={"../assets/pintrest.svg"}
                                                style={{borderRadius: "10px", width: "43px"}}
                                                />
                                        </a>
                                            &nbsp;
                                            &nbsp;
                                        <a
                                            target="_blank"
                                            href="https://www.facebook.com/GenderRevealGame/"
                                            rel="noreferrer"
                                        >
                                            <Image 
                                                preview={false} 
                                                className="icons" 
                                                alt="facebook" 
                                                src={"../assets/facebook.svg"}
                                                style={{borderRadius: "15px", width: "47px"}}
                                            />
                                        </a>
                                    </div>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                    <div className="block3">
                        <p>This Chinese Gender Predictor Tool is brought to you by:</p>
                        <div>
                            <b className="bold">Villie Inc.</b>
                        </div>
                    </div>
                </div>
            </Layout>
            <Modal visible={genderAnnounce} onCancel={() => setGenderAnnounce(false)} footer={null} className="predictionModal">
                <Title className="title" style={{marginTop: "5%"}}>CONGRATULATIONS!</Title>
                <Image className="genderImage" preview={false} alt="gender-sex" src={gender === "Girl"?  "../assets/12.svg" : "../assets/13.svg"}/>
                <Title className="title">We predict you're having a {gender}!</Title>
                <Paragraph className="paragraph">Ready to bring your firends and family in on the fun? Learn about our Gender Reveal Game <a style={{color: "#CC8DFF"}} href="https://www.villie.co/genderrevealgame-home" target='_blank'	rel="noopener noreferrer">here</a>.</Paragraph>
            </Modal>
        </>
    );
};

export default Predictor